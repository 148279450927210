.callout__home {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include respond-to(959px) {
        background-image: var(--background-image-url);
        color: $body-color;
    }

    &__container {
        max-width: 100%;

        @include respond-to(959px) {
            max-width: 1280px;
            margin: 0 auto;
        }
    }

    &__image {
        display: block;

        @include respond-to(959px) {
            display: none;
        }
    }

    &__content {
        max-width: 650px;
        padding: 1rem 2rem 2rem;
        margin: 0 auto;

        @include respond-to(959px) {
            margin-left: 40px;
            padding: 5rem;
            background-color: rgba(0, 0, 0, .6);
        }
    }

    &__title {
        @include font-size($alpha);
        
        @include respond-to(959px) {
            color: $body-color;
        }
    }

    &__description {
        @include font-size($delta);
        margin-bottom: 2rem;
    }

    &__link {
        @include respond-to(959px) {
            color: $body-color;
            border-color: $body-color;

            &:hover {
                border-color: $gnr-sunset-a11y;
            }
        }
    }
    
}
