.feature-section {
    text-align: center;
    padding: 0 2rem;
    margin: 4rem 0;

    @include respond-to(959px) {
        max-width: 1280px;
        margin: 4rem auto;
        text-align: left;
    }

    &__title {
        @include font-size($beta);

        @include respond-to(959px) {
            @include font-size($alpha-md);
        }
    }

    &__content {
        font-size: 20px;

        @include respond-to(959px) {
            font-size: 24px;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        margin-top: 2rem;

        @include respond-to(768px) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }

        @include respond-to(959px) {
            margin-top: 4rem;
        }

        @include respond-to(1280px) {
            justify-content: space-between;
        }
    }

    &__item {
        text-align: center;
        color: $gnr-slate;

        @include respond-to(768px) {
            width: calc(50% - 4rem);
        }

        @include respond-to(959px) {
            width: calc(33% - 4rem);
        }

        @include respond-to(1280px) {
            width: calc(20% - 4rem);
        }
    }

    &__item-title {
        font-size: 21px;
        font-weight: 600;
    }

    &__item-content {
        font-size: 18px;
    }

    &__item-image {
        width: 120px;
        height: 120px;
        margin: 0 auto 2rem;
        display: block;

        @include respond-to(959px) {
            width: 150px;
            height: 150px;
        }

        @include respond-to(1280px) {
            width: 100%;
            height: fit-content;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2), 0 -1rem 1rem rgba(0, 0, 0, 0.1), 
            1rem 0 1rem rgba(0, 0, 0, 0.1), -1rem 0 1rem rgba(0, 0, 0, 0.1);
        }
    }

    &__button {
        margin-top: 2rem;
    }

    &__button-link {
        @include font-size($delta);
    }
}