.cta__home {
    @include font-size($delta);
    background-color: $gnr-slate;
    color: $body-color;
    max-width: 700px;
    margin: 4rem 2rem;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    @include respond-to(768px) {
        margin: -6rem auto 2rem;
    }

    &__link {
        color: $body-color;
        border-color: $body-color;
        margin-top: 2rem;

        &:hover {
            border-color: $gnr-sunset-a11y;
        }
    }
}
