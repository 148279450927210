.publication-section {
    padding: 0 2rem;
    margin: 4rem 4rem 0;
    background-color: #f3f8f8;

    &__container {
        margin: 0 auto;
        @include respond-to(959px) {
            max-width: 1280px;
        }
    }

    &__title {
        @include font-size($alpha);
    }

    &__items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        
        @include respond-to(768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        display: flex;
        gap: 1rem;;
    }

    &__item-image {
        display: block;
        width: 120px;
        height: 120px;
        object-fit: cover;
    }

    &__item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__item-date {
        font-size: 1.2rem;
        font-style: italic;
    }

    &__item-title {
        @include font-size($delta);
    }

    &__item-description {
        font-size: 1.2rem;
    }

    &__item-link,
    &__link {
        @include font-size($delta);
        width: fit-content;
    }

    &__link {
        display: block;
        margin-top: 2rem;
    }
}


